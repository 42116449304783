import React, { useContext, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ErrorContext } from '../../store/ErrorContext'
import PortalEntry from '../../pages/PortalEntry'
import { WithSuspense } from '../../utils/helpers'
import classes from './LiveUi.modules.scss'
import {
  UiTheme,
  useMultiUiBrandContext
} from '@jarvis/react-setup-and-onboarding-addons'

const Completed = lazy(() =>
  import(/* webpackChunkName: "page-completed" */ '../../pages/Completed')
)
const LanguageCountry = lazy(() =>
  import(
    /* webpackChunkName: "page-lang-country" */ '../../pages/LanguageCountry'
  )
)
const LoadPaper = lazy(() =>
  import(/* webpackChunkName: "page-load-paper" */ '../../pages/LoadPaper')
)
const InstallCartridges = lazy(() =>
  import(
    /* webpackChunkName: "page-install-ink" */ '../../pages/InstallCartridges'
  )
)
const InstallPha = lazy(() =>
  import(/* webpackChunkName: "page-install-pha" */ '../../pages/InstallPha')
)
const FillInkTanks = lazy(() =>
  import(/* webpackChunkName: "page-fill-ink" */ '../../pages/FillInkTanks')
)
const PrintAlignment = lazy(() =>
  import(
    /* webpackChunkName: "page-align-print" */ '../../pages/PrintAlignment'
  )
)
const ScanAlignment = lazy(() =>
  import(/* webpackChunkName: "page-align-scan" */ '../../pages/ScanAlignment')
)
const LanguageCountrySelector = lazy(() =>
  import(
    /* webpackChunkName: "page-lang-country-selector" */ '../../pages/LanguageCountrySelector'
  )
)
const RemoveProtectiveSheet = lazy(() =>
  import(
    /* webpackChunkName: "page-remove-sheet" */ '../../pages/RemoveProtectiveSheet'
  )
)
const RemoveWrap = lazy(() =>
  import(/* webpackChunkName: "page-remove-wrap" */ '../../pages/RemoveWrap')
)
const GenericErrorModal = lazy(() =>
  import(/* webpackChunkName: "modal-generic" */ '../LiveUi/GenericErrorModal')
)
const AutoAlignment = lazy(() =>
  import(/* webpackChunkName: "auto-alignment" */ '../../pages/AutoAlignment')
)

const LiveUi = () => {
  const { error } = useContext(ErrorContext)
  const { uiBrand } = useMultiUiBrandContext()
  const isHPX = uiBrand === UiTheme.hpx

  return (
    <div
      data-testid={isHPX ? 'HPX' : 'HP-Smart'}
      className={isHPX ? classes['hpx-theme'] : ''}
    >
      {error && (
        <WithSuspense component={GenericErrorModal} props={{ error: error }} />
      )}
      <Routes>
        <Route
          path="completed"
          element={<WithSuspense component={Completed} />}
        />
        {['languageCountry', 'languageConfig', 'countryConfig'].map((path) => (
          <Route
            key={path}
            path={path}
            element={<WithSuspense component={LanguageCountrySelector} />}
          />
        ))}
        <Route
          path="languageCountryStatus"
          element={<WithSuspense component={LanguageCountry} />}
        />
        <Route
          path="loadMainTray"
          element={<WithSuspense component={LoadPaper} />}
        />
        <Route
          path="fillInkTanks"
          element={<WithSuspense component={FillInkTanks} />}
        />
        <Route
          path="installPha"
          element={<WithSuspense component={InstallPha} />}
        />
        {['installCartridges', 'insertInk'].map((path) => (
          <Route
            key={path}
            path={path}
            element={<WithSuspense component={InstallCartridges} />}
          />
        ))}
        <Route
          path="calibration"
          element={<WithSuspense component={AutoAlignment} />}
        />
        {['semiAutoCalibration', 'semiCalibrationPrint'].map((path) => (
          <Route
            key={path}
            path={path}
            element={<WithSuspense component={PrintAlignment} />}
          />
        ))}
        {['scanAlignment', 'semiCalibrationScan'].map((path) => (
          <Route
            key={path}
            path={path}
            element={<WithSuspense component={ScanAlignment} />}
          />
        ))}
        <Route
          path="removeProtectiveSheet"
          element={<WithSuspense component={RemoveProtectiveSheet} />}
        />
        <Route
          path="removeWrap"
          element={<WithSuspense component={RemoveWrap} />}
        />
        {/* Portal entry path */}
        <Route exact path="/" element={<PortalEntry />} />
      </Routes>
    </div>
  )
}
export default LiveUi
